<template>
  <div :style="{ width: width }">
    <label :for="id">{{ label }}</label>
    <v-text-field
      ref="input"
      :id="id"
      :height="height"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      :type="type"
      rounded
      class="custom-input rounded-pill ma-0 pa-0"
      @input="$emit('update', $event)"
      :rules="rules"
      :counter="counter"
    />
  </div>
</template>
<script>
export default {
  name: "TextField",
  props: {
    id: {
      Number,
      required: true
    },
    value: {
      String,
      required: true
    },
    label: {
      String,
      required: false
    },
    type: {
      String,
      required: false,
      default: "text"
    },
    height: {
      Number,
      required: false,
      default: 41
    },
    rules: {
      Object,
      required: false
    },
    counter: {
      Boolean,
      required: false
    },
    width: {
      Number,
      required: false,
      default: 250
    }
  },
  methods: {
    resetValidation() {
      this.$refs.input.resetValidation(); // Reset validation state
    }
  }
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border: none;
}
label {
  color: #707070;
  font-family: "Lato", serif;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
}
>>> .v-input__icon--append {
  height: 31px;
}
>>> .v-input__icon--append .v-icon {
  font-size: 18px;
}

>>> .v-input__icon--prepend-inner .v-icon {
  margin-top: 7px;
}
</style>
