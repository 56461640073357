export const infinityScrollMixin = {
  data() {
    return {
      endOfTheScreen: false
    };
  },
  created() {
    window.onscroll = () => {
      this.endOfTheScreen = this.scrollCheck();
    };
  },
  methods: {
    scrollCheck() {
      return (
        window.scrollY + window.innerHeight >
        document.documentElement.offsetHeight - 200
      );
    }
  }
};
