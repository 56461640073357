<template>
  <v-avatar
    v-bind="$attrs"
    v-on="$listeners"
    size="38"
    class="agent-avatar"
    @click="handleClick"
  >
    <img :src="imageHost + item.image" :alt="item.name" :title="item.name" />
    <div v-if="item.status == 0" class="inactive-avatar"></div>
    <div v-if="item.status == 1" class="active-avatar"></div>
  </v-avatar>
</template>
<script>
export default {
  name: "Avatar",
  created() {
    this.imageHost = process.env.VUE_APP_THUMBNAILS_PATH;
  },
  data() {
    return {
      imageHost: ""
    };
  },
  props: {
    user: {
      Boolean,
      required: false,
      default: true
    },
    item: {
      Object,
      required: true
    }
  },
  methods: {
    handleClick() {
      this.$emit("clicked");
    }
  }
};
</script>
<style scoped></style>
