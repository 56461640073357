<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="selected"
        v-on:input="$emit('input', $event)"
        v-on="on"
        color="green lighten-1"
        class="custom-input rounded-pill pa-0 ma-0"
        rounded
      >
        <template v-slot:append>
          <img
            class="calendar-icon"
            width="25"
            height="25"
            :src="require('@/assets/calendar.svg')"
            alt="calendar-icon"
          />
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="selected"
      no-title
      header-color="green lighten-1"
      color="green lighten-1"
      scrollable
      :disabled="disabled"
    >
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="
          menu1 = false;
          selected = '';
        "
        >Cancel</v-btn
      >
      <v-btn color="primary" text @click="$refs.menu1.save(selected)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      menu1: null,
      date: null
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
<style scoped>
/*>>> .v-input__icon--append-inner {*/
/*  margin-top: 7px;*/
/*  background: red !important;*/
/*}*/
.calendar-icon {
  margin-top: 2px;
}
</style>
