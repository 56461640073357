<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="prolongations"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      :header-props="{ sortIcon: null }"
      v-model="selectedTasks"
      show-select
      :options.sync="options"
      :server-items-length="total"
    >
      <template v-slot:top>
        <v-toolbar flat extended extension-height="100">
          <v-toolbar-title id="toolbar-title">Historique des demandes</v-toolbar-title>
          <v-switch
          v-model="statusSwitch"
          :label="switchLabel"
          color="#86bc26"
          hide-details
          inset
          style="margin-left: 20px;"
          ></v-switch>
          <v-spacer></v-spacer>
          <span class="mr-5 total-hours">
            <em class="total">Total : </em>
            {{ requests }} demandes
          </span>
            <Button class="mb-2" action_name="Autoriser la modification" color="primary" text @clicked="dialogConfirmExtend=true;"/>
          <v-dialog v-model="dialogConfirmExtend" max-width="600px">
            <v-card>
              <v-card-title class="headline">Etes vous sûr d'autoriser la modification de ce rapport ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark darken-5" text @click="dialogConfirmExtend = false">Cancel</v-btn>
                <v-btn color="secondary darken-5" text @click="handleAuthorize(1)">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="filterDialog" max-width="836px">
            <v-card>
              <v-card-title  class="justify-space-between">
                <span class="headline">Filtre</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB" >mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete id="agent" v-model="editedItem.agent" :items="agents" label="Agent" item-text="name" return-object multiple deletable-chips small-chips />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete id="dossier" v-model="editedItem.department" :items="departments" label="Département" item-text="name" multiple deletable-chips small-chips return-object />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete id="type" v-model="editedItem.type" :items="request_types" label="Type" item-text="name" deletable-chips small-chips return-object />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pb-0" v-if="!disabledExactDate">
                      <label>Date</label>
                      <v-checkbox dense hide-details class="ma-0 pa-0 d-inline-block select-multiple-date" v-model="checkDate" @change="handleMultipleDate"></v-checkbox>
                      <custom-date-picker v-model="editedItem.exact_date" />
                    </v-col>
                    <v-col cols="12" md="3" sm="6" class="ma-0 pb-0" v-if="showMultipleDates">
                      <label>Date début</label>
                      <v-checkbox dense hide-details class="ma-0 pa-0 d-inline-block select-multiple-date" v-model="checkDate" @change="handleMultipleDate(false)"></v-checkbox>
                      <custom-date-picker v-model="editedItem.start_date" />
                    </v-col>
                    <v-col cols="12" md="3" sm="6" class="ma-0 pb-0" v-if="showMultipleDates">
                      <label>Date fin</label>
                      <custom-date-picker v-model="editedItem.end_date" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <Button action_name="Filtrer" color="#86CC26" @clicked="save(true)" style="width: 220px" class="ml-5 mr-5 mb-5" :loading="loadingFilter"/>
                <span class="mb-5" style="color:#B2BBAB" @click="handleReset">Réinitialiser</span>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <template v-slot:extension>
            <div class="filter-block">
              <div>
                <template v-for="(agent, index) in editedItem.agent">
                  <v-chip
                    class="ma-1"
                    :key="agent.name"
                    close
                    @click:close="handleChipDelete(index, 'agent')">Agent:{{ agent.name }}</v-chip>
                </template>
                <template v-for="(department, index) in editedItem.department">
                  <v-chip
                      class="ma-1"
                    :key="department"
                    close
                    @click:close="handleChipDelete(index, 'department')"
                    >Departement:{{ department }}</v-chip
                  >
                </template>
                <template v-if="editedItem.type">
                  <v-chip
                      class="ma-1"
                    close
                    @click:close="handleExactChipDelete('type')"
                    >Type:{{ editedItem.type.name }}</v-chip>
                </template>
                <template v-if="editedItem.exact_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete(index, 'exact_date')">Date:{{ editedItem.exact_date }}</v-chip>
                </template>
                <template v-if="editedItem.start_date && editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleMultipleDateDelete">Entre le :{{ editedItem.start_date }} et le : {{ editedItem.end_date }}</v-chip>
                </template>
                <template v-if="editedItem.start_date && !editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('start_date')">Aprés le :{{ editedItem.start_date }}</v-chip>
                </template>
                <template v-if="!editedItem.start_date && editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('end_date')">Avant le :{{ editedItem.end_date }}</v-chip>
                </template>
              </div>
              <div>
                <v-chip  class="chip-delete">
                  <v-icon @click="filterDialog = true" outlined>mdi-filter</v-icon>
                </v-chip>
              </div>
            </div>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.timesheet.start_date="{ item }">
        {{ item.timesheet.start_date | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.agent="{ item }">
        <Avatar :item="item.agent" />
        <span class="ml-2">{{ item.agent.name }}</span>
      </template>
      <template v-slot:item.type="{ item }">
        <span v-if="item.type === 1">Prolongation</span>
        <span v-if="item.type === 2">Modification</span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status === 0" class="outlined inactive-status" >mdi-close</v-icon>
        <v-icon v-if="item.status === 1" class="outlined active-status" >mdi-check</v-icon>
      </template>
      <template v-slot:item.timesheet.updated_at="{item}">
        <span v-if="item.timesheet.updated_at > item.updated_at">
          {{item.timesheet.updated_at | moment("dddd DD/MM/YYYY") }}
        </span>
        <span v-else>
          Pas encore
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { Avatar, Button, AutoComplete, CustomDatePicker } from "@/components/base";
import { getAllProlongations } from "@/services/prolongation.api";
import { getAllActiveUsers } from "@/services/users.api";
import departments from "@/assets/data/departments.json";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";
import { extendEditTimeSheet } from "@/services/timesheet.api";

export default {
  components: { AutoComplete, Avatar, Button, CustomDatePicker },
  mixins: [infinityScrollMixin],
  data: () => ({
    lastUpdate:"",
    total:0,
    options: {},
    request_types: [{ id: 1, name: "Prolongation" },{ id: 2, name: "Modification" }],
    request_status: [{ id: 1, name: "Accepté" },{ id: 0, name: "Non accepté" }],
    dialogConfirmExtend: false,
    selectedTasks: [],
    loadingFilter: false,
    disabledExactDate: false,
    showMultipleDates: false,
    checkDate: false,
    departments: departments,
    filterDialog: false,
    agents: [],
    editedItem: {status: { id: 1, name: "Autorisé" }},
    prolongations: [],
    items: [],
    headers: [
      {
        text: "Agent",
        align: "start",
        sortable: false,
        value: "agent"
      },
      { text: "Date de la demande", value: "created_at" },
      { text: "Date du rapport", value: "timesheet.start_date" },
      { text: "Date de remplissage", value: "timesheet.updated_at" },
      { text: "Département", value: "agent.department" },
      { text: "Type de la demande", value: "type" },
      { text: "Raison", value: "raison" },
      { text: "Statut", value: "status" }
      //{ text: "Actions", value: "actions", sortable: false }
    ],
    totalRequests: 0,
    pageNumber: 1,
    last_page: 0
  }),
  computed: {
    requests() {
      return this.totalRequests;
    },
    switchLabel() {
      return this.statusSwitch ? 'Autorisé' : 'Non encore autorisé';
    },
    statusSwitch: {
      get() {
        return this.editedItem.status.name === "Autorisé";
      },
      set(value) {
        this.editedItem.status = value
          ? { id: 1, name: "Autorisé" }
          : { id: 0, name: "Non encore autorisé" };
      }
    },
  },
  watch: {
    options: {
      handler () {
        this.editedItem.order_by = this.options.sortBy[0]
        this.editedItem.order_by_direction = this.options.sortDesc[0]
        this.prolongations = []
        this.pageNumber = 1
        getAllProlongations(this.pageNumber, this.editedItem).then(response => {
          this.prolongations = response.data.data;
          this.last_page = response.data.last_page;
          this.totalRequests = response.data.total;
          this.total=response.data.total
        });
      },
      deep: true,
    },
    'editedItem.status': {
      handler() {
        this.loadingFilter= true;
        this.pageNumber = 1;
        getAllProlongations(this.pageNumber, this.editedItem).then(response => {
          this.loadingFilter= false;
          this.prolongations = response.data.data;
          this.last_page = response.data.last_page;
          this.totalRequests = response.data.total;
          this.filterDialog = false;
        });
      },
      deep: true
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true && this.pageNumber < this.last_page) {
        this.pageNumber += 1;
        getAllProlongations(this.pageNumber, this.editedItem).then(response => {
          this.prolongations = [...this.prolongations, ...response.data.data];
        });
      }
    },
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    handleAuthorize(dayNumber) {
      var selectedExtanded = { id: [], plus_day: 0 };
      for (let i in this.selectedTasks) {
        selectedExtanded.id.push(this.selectedTasks[i].id);
      }
      selectedExtanded.plus_day = dayNumber;
      extendEditTimeSheet(selectedExtanded).then(() => {
        this.dialogConfirmExtend = false
        this.$swal.fire({ title: "Rapport(s)  autorisé(s) avec succès" });
        this.pageNumber = 1
        getAllProlongations(this.pageNumber).then(response => {
          this.prolongations = response.data.data;
          this.last_page = response.data.last_page;
          this.totalRequests = response.data.total;
        });
      });
    },
    handleMultipleDateDelete() {
      this.editedItem["start_date"] = "";
      this.editedItem["end_date"] = "";
      this.save();
    },
    handleMultipleDate(status) {
      if (status === true) {
        this.editedItem["exact_date"] = "";
      } else {
        this.editedItem["start_date"] = "";
        this.editedItem["end_date"] = "";
      }
      this.showMultipleDates = !this.showMultipleDates;
      this.disabledExactDate = !this.disabledExactDate;
    },
    handleExactChipDelete(key){
      this.editedItem[key] = "";
      this.save();
    },
    handleReset() {
      this.editedItem = {};
      this.save();
    },
    handleDateDelete(key) {
      this.editedItem[key] = "";
      this.save();
    },
    handleChipDelete(index, key) {
      this.editedItem[key].splice(index, 1);
      this.save();
    },
    close() {
      this.filterDialog = false;
    },
    save() {
      this.loadingFilter= true;
      this.pageNumber = 1;
      getAllProlongations(this.pageNumber, this.editedItem).then(response => {
        this.loadingFilter= false;
        this.prolongations = response.data.data;
        this.last_page = response.data.last_page;
        this.totalRequests = response.data.total;
        this.filterDialog = false;
      });
    },
    initialize() {
      getAllProlongations(this.pageNumber).then(response => {
        this.prolongations = response.data.data;
        this.last_page = response.data.last_page;
        this.totalRequests = response.data.total;
      });
      getAllActiveUsers().then(response => { this.agents = response.data;});
    }
  }
};
</script>
<style scoped>

.select-multiple-date {
  margin-top: -5px !important;
}
</style>