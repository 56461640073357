import httpClient from "./httpClient";

const END_POINT = "/api/users";
const createUser = (user) => httpClient.post(END_POINT, user);
const getAllUsers = () => httpClient.get(END_POINT);
const getAllActiveUsers = () => httpClient.get(END_POINT + "/active");
const changePassword = (password, newpassword, c_newpassword) =>
  httpClient.post(END_POINT + "/change-password", {
    password,
    newpassword,
    c_newpassword,
  });
const getUser = () => httpClient.get(END_POINT + "/get-user");
const getUserById = (id) => httpClient.get(END_POINT + "/getById/" + id);
const UpdatePhoto = (image) =>
  httpClient.post(END_POINT + "/change-image", image);
const updateUserInfo = (user) => httpClient.post(END_POINT + "/update", user);
const searchUser = (name) => httpClient.get("/api/search/users/" + name);
const updatePhoto = (photo) =>
  httpClient.post(END_POINT + "/change-image", photo);
const getActiveUsers = () => httpClient.get(END_POINT + "/getActiveUsers");
export {
  createUser,
  getAllUsers,
  changePassword,
  getUser,
  UpdatePhoto,
  updateUserInfo,
  getUserById,
  getAllActiveUsers,
  searchUser,
  updatePhoto,
  getActiveUsers,
};
