import httpClient from "./httpClient";

const END_POINT = "/api/timesheet";

const addTimeSheetLine = (timesheet) =>
  httpClient.post(END_POINT + "/line", timesheet);
const getTimeSheet = (id) => httpClient.get(END_POINT + "lines/" + id);
const getTimeSheetList = (endDate) =>
  httpClient.get(END_POINT + "/filter/" + endDate);
const deleteTimeSheetLine = (id) =>
  httpClient.delete(END_POINT + "/line/" + id);
const updateTimeSheetLine = (timesheet) =>
  httpClient.put(END_POINT + "/line/" + timesheet.id, timesheet);
const getAllTimeSheetByAdmin = (page, filterList) =>
  httpClient.post(END_POINT + "/all?page=" + page, filterList);
const getAllTimeSheetManquantByAdmin = (page, filterList) =>
  httpClient.post(END_POINT + "/all/manquants?page=" + page, filterList);
const updateTimeSheet = (selectedExtanded) =>
  httpClient.put(END_POINT, selectedExtanded);
const ignoreTimeSheet = (selectedIgnored) =>
  httpClient.post(END_POINT + "/ignore", selectedIgnored);
const holidayTimeSheet = (selectedHolidays) =>
  httpClient.post(END_POINT + "/holiday", selectedHolidays);
const checkTimeSheet = (date) =>
  httpClient.post(END_POINT + "/check", { date });
const getAllTimeSheetIgnoredByAdmin = (page, filterList) =>
  httpClient.post(END_POINT + "/ignored?page=" + page, filterList);
const recoverTimeSheet = (selectedRecovered) =>
  httpClient.post(END_POINT + "/recover", selectedRecovered);
const getAllTimeSheetExported = (filterList) =>
  httpClient.post(END_POINT + "/export", filterList, { responseType: "blob" });
const extendEditTimeSheet = (selectedExtanded) =>
  httpClient.put(END_POINT + "/extend", selectedExtanded);
const updateTimeSheetTitle = (timesheet) =>
  httpClient.put(END_POINT + "/updateTitle", timesheet);
const navigationTimeSheet = (switchTimesheet) =>
  httpClient.post(END_POINT + "/switch", switchTimesheet);
const getYears = () => httpClient.get(END_POINT + "/getYears");
const getTimesheetStartDatesForUser = () => httpClient.get(END_POINT + "/dates");
const addCongeTimeSheetLines = (timesheet) =>
  httpClient.post(END_POINT + "/conge/lines", timesheet);
const getTimesheetDatesBasedOnParams = (params) =>
  httpClient.post("/api/getTimesheetDatesBasedOnParams", params);
const addMultipleProlongations = (prolongation) =>
  httpClient.post("api/addMultipleProlongations", prolongation);
export {
  addTimeSheetLine,
  getTimeSheet,
  getTimeSheetList,
  deleteTimeSheetLine,
  updateTimeSheetLine,
  getAllTimeSheetByAdmin,
  getAllTimeSheetManquantByAdmin,
  updateTimeSheet,
  ignoreTimeSheet,
  holidayTimeSheet,
  checkTimeSheet,
  getAllTimeSheetIgnoredByAdmin,
  recoverTimeSheet,
  getAllTimeSheetExported,
  extendEditTimeSheet,
  updateTimeSheetTitle,
  navigationTimeSheet,
  getYears,
  getTimesheetStartDatesForUser,
  addCongeTimeSheetLines,
  getTimesheetDatesBasedOnParams,
  addMultipleProlongations
};
