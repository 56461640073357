<template>
  <div>
    <label :for="id">{{ label }}</label>
    <v-autocomplete
      :id="id"
      :height="height"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      rounded
      solo
      class="select-input"
      @input="$emit('update', $event)"
      append-icon="mdi-chevron-down"
      :menu-props="{ rounded: 'xl' }"
    />
  </div>
</template>
<script>
export default {
  name: "AutoComplete",
  props: {
    id: {
      Number,
      required: true
    },
    value: {
      String,
      required: true
    },
    label: {
      String,
      required: false
    },
    height: {
      Number,
      required: false
    }
  }
};
</script>
<style scoped>
.select-input.v-input /deep/ .v-input__slot {
  /*border-radius: 100px;*/
  border: 1px solid #cecece;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px !important;
  /*FIXED TO OVERRIDE MIN HEIGHT*/
  /*min-height: auto !important;*/
  display: flex !important;
  align-items: center !important;
  min-height: 40px !important;
}

/*.select-input.v-input /deep/ .v-select__slot {*/
/*  position: absolute;*/
/*  right: 10px;*/
/*}*/
/*.v-autocomplete__content.v-menu__content /deep/ {*/
/*  color: red !important;*/
/*  background: red !important;*/
/*  transform-origin: center top !important;*/
/*  transform: scale(0.97) !important;*/
/*}*/
/*.v-autocomplete__content.v-menu__content {*/
/*  transform-origin: center top !important;*/
/*  transform: scale(0.5) !important;*/
/*  background: red !important;*/
/*}*/
label {
  color: #707070;
  font-family: "Lato", serif;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
}

/*.v-autocomplete .v-select-list .v-subheader {*/
/*  background: red !important;*/
/*  color: red !important;*/
/*}*/
</style>
