import axios from "axios";
import router from "vue-router";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;
const httpClient = axios.create({
  baseURL: `${VUE_APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*"
  }
});

httpClient.interceptors.request.use(
  function(config) {
    if (localStorage.getItem("token")) {
      config.headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json"
      };
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

// eslint-disable-next-line camelcase
function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}
function REFRESH_TOKEN() {
  if (!localStorage.getItem("token")) {
    return;
  }
  return axios.post(VUE_APP_BASE_URL + "/api/refresh", {
    refresh_token: localStorage.getItem("refresh_token")
  });
}
httpClient.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const {
      config,
      response: { status, data }
    } = error;
    const originalRequest = config;
    if (data.message === "Invalid scope(s) provided.") {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      router.push("login");
    }
    if (status === 401 && localStorage.getItem("token")) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        REFRESH_TOKEN().then(res => {
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          isAlreadyFetchingAccessToken = false;
          onAccessTokenFetched(res.data.access_token);
        });
      }

      return new Promise(resolve => {
        addSubscriber(res => {
          originalRequest.headers.Authorization = "Bearer " + res;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  }
);

export default httpClient;
