<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :loading="loading"
    class="custom-btn elevation-0"
    rounded
    type="button"
    v-on:click="handleClick"
  >
    <template v-slot:loader>
      <span class="custom-loader">
        <v-icon light>mdi-cached</v-icon>
      </span>
    </template>
    <slot name="button-content"></slot>
    <span class="custom-btn-text pr-6 pl-6">{{ action_name }}</span>
  </v-btn>
</template>
<script>
export default {
  name: "Button",
  props: {
    disabled: {
      Boolean,
      required: false,
      default: false
    },
    action_name: {
      String,
      required: true,
      default: "Envoyer"
    },
    loading: {
      Boolean,
      required: false,
      default: false
    },
    click_action: {
      Function,
      required: false
    },
    color: {
      String,
      required: false,
      default: "#86CC26"
    },
    textColor: {
      String,
      required: false,
      default: "white"
    }
  },
  methods: {
    handleClick() {
      this.$emit("clicked");
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-btn {
  border: 1px solid #6e9c1c !important;
  //background: #86bc26 !important;
}

.custom-btn-text {
  color: white;
  font-family: "Segoe UI", serif;
  font-size: $font-size-root;
  /*17px*/
  text-transform: none;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  border: 1px solid #c9c9c9 !important;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
// Loader animation
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
