<template>
  <div>
    <label :for="id">{{ label }}</label>
    <v-textarea
      :id="id"
      :height="height"
      v-bind="$attrs"
      v-on="$listeners"
      auto-grow
      rounded
      solo
      :value="value"
      :rules="rules"
      @input="$emit('update', $event)"
    />
  </div>
</template>
<script>
export default {
  name: "textArea",
  props: {
    id: {
      Number,
      required: true
    },
    height: {
      Number,
      required: false,
      default: "auto"
    },
    label: {
      String,
      required: false
    },
    value: {
      String,
      required: true
    },
    rules: {
      Object,
      required: false
    },
  }
};
</script>
<style scoped>
label {
  color: #707070;
  font-family: "Lato", serif;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
}
</style>
